<template>
  <ValidationProvider
    tag="div"
    class="question single-answer column"
    :class="width"
    :rules="validationRules"
    v-slot="{ errors }"
  >
    <div class="question-text">
      <p class="question-text__code" v-if="showQuestionCode">
        {{ question.code }}
      </p>
      <p class="question-text__question" v-html="decodeHtml(question.question[lang])"></p>
      <p class="help is-danger" v-if="errors[0]">{{ errors[0] }}</p>
      <p class="help" v-else>{{ questionHelp }}</p>
    </div>
    <div class="question-content card">
      <div class="card-content">
        <b-field>
          <b-datetimepicker
            :placeholder="$t('datetime.placeholder')"
            icon="calendar-today"
            :locale="$t('locale')"
            horizontal-time-picker
            v-bind="{...customizedProps}"
            @input="onChange"
            v-model="data"
          >
          </b-datetimepicker>
        </b-field>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { QuestionMixin } from "@/mixins/question";
import { UnFocusMixin } from "@/mixins/unfocus";
export default {
  name: "DatetimePicker",
  mixins: [QuestionMixin, UnFocusMixin],
  data() {
    return {
      data: null,
    };
  },
  methods: {
    onChange(value) {
      this.$emit("input", { [this.question.qid]: value?.toISOString() });
    },
  },
  created() {
    setTimeout(() => {
      const value = this.value[this.question.qid];
      if (value) {
        this.data = new Date(value);
        this.unFocus();
      }
    }, 300);
  },
};
</script>
